import React, { useEffect, useState } from 'react';
import { get, post, del } from '@/utils/request';
import Loading from '@/components/loading';
import { colorCodes } from '../config';
import SVGProductionModule from './SVGProductionModule';
import { useMainStore } from '@/mainStore';


//import { tabModules } from './statistics';
const statusClass = {
    in_production: 'badge-primary',
    finished: 'badge-success',
    done: 'badge-success'
};

export default function Production( { lang } ){
 
    const [ isLoading, setLoading ] = useState( true );
    const [ toProduce, setToProduce ] = useState( [] );
    
    const { 
   //     ordersToProduce, 
   //     setOrdersToProduce,
        loadOrders,
        setProduction, 
        createMaterialArray, 
        splitOrderByMaterial, 
        materialOrders, 
        setMaterialOrders,
        isEditing, 
        setEditing, 
        setActiveProduction, 
        activeProductionOrders, 
        setActiveProductionOrders,
        createProduction
    } = useMainStore(  );



    const selectOrder = ( order ) => {
       
        let selected = [ ...toProduce ];
        const exists = toProduce.find( o => o?.idx === order?.idx );
        if( !exists ){
            selected.push( order );
            
        } else {
            selected = toProduce.filter( o => o?.idx !== order?.idx );
        }

        setToProduce( selected );
        setProduction( [] );
        
    }

    const createNewProduction = () => {

        createProduction( toProduce );
        setToProduce( [] );
    }

    const toggleSelectedOrders = (e) => {
        setToProduce( e.target.checked ? materialOrders : [] );
    }

    const editProduction = ( prodOrder ) => {

        setActiveProduction( prodOrder );
        setEditing( true );
    }

    useEffect( () => {
        const bootstrap = async() => {
            await loadOrders();
            setLoading( false );
        }
        bootstrap();
    }, [] )

    return (
        
        isLoading ? ( <Loading /> ) :
        (
        <div className="container-fluid">
            
                <div id="moduleContent" style={{ marginBottom: 10 }}>
                    <h3>Produktion</h3>
                    {
                        !isEditing && 
                        <div className={`d-flex flex-wrap`}>
                        <div className="p-2 flex-grow-1">

                            <h5>Ordrar att producera</h5>
                            <table className="table dataTable table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th className="align-middle"><input className="m-2" style={{ width: 20, height: 20}} type="checkbox" onChange={ (e) => toggleSelectedOrders(e) } /></th>
                                        <th className="align-middle">OrderId</th>
                                        <th className="align-middle">Material</th>
                                        <th className="align-middle number">Antal skylt</th>
                                        
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    materialOrders.map( (o ,i) => {
                                       
                                        const [material, color] = o.material.split(" ");
                                        const [bg, text] = color.split("/");
                                        return (
                                            <tr key={ i } onClick={ () => selectOrder( o ) } className={ toProduce.find( or => or?.idx === o?.idx ) ? "bg-light": "" } >
                                                <td>
                                                    <input type="checkbox" className="m-2" style={{ width: 20, height: 20}} checked={ toProduce.find( or => or?.idx === o?.idx ) ? true: false } onChange={ () => selectOrder( o ) }  />
                                                </td>
                                                <td className="align-middle">{ o.id }</td>
                                                <td className="align-middle"> 
                                                    <div  className="d-flex" >

                                                        <div className="p-1">{lang.materials[ material ] } </div>
                                                        <div 
                                                            style={{ backgroundColor: colorCodes[ bg ], color: colorCodes[ text ], border:'1px solid black', width: 'auto', height: 30, textAlign:'center' }}
                                                            className="p-1"
                                                        >
                                                            {color}
                                                        </div> 
                                                    </div>
                                                </td>
                                                <td className="align-middle number">
                                                    {
                                                        o.items.reduce( (acc, item) =>  ( acc += item.quantity ), 0 )
                                                    }
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                            <button className="mt-2 btn btn-sm btn-primary " onClick={ () => createNewProduction( ) }>Skapa produktion</button>
                        </div>
                        <div className="p-2 flex-grow-1">
                            <h5>Aktiva produktionsordrar</h5>
                            <table className="table dataTable table-hover mb-0">
                                <thead>
                                    <tr>
                                        
                                        <th className="align-middle">ProductionsId</th>
                                        <th className="align-middle">Skapad/Status</th>
                                        <th className="align-middle">Namn</th>
                                        <th className="align-middle">Inkluderar ordrar</th>
                                        
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    activeProductionOrders.map( (o ,i) => {
                                       // console.log(' prod oprder ', o );
                                        return (
                                            <tr key={ i } onClick={ () => editProduction( o ) }  >

                                                <td className="align-middle">{ o.id }</td>
                                                <td className="align-middle"> 
                                                    { new Date(o.createdAt).toISOString().replace('T', ' ').slice(0, -5) }
                                                    <div className=""><span className={ `badge ${statusClass[o.status]}`}>{ lang[ o.status ] }</span></div>
                                                </td>
                                                <td className="align-middle"> 
                                                    { o.name }
                                                </td>

                                                <td className="align-middle number">
                                                    {
                                                        o?.salesOrders?.length > 0 &&
                                                        o.salesOrders.map( so => {
                                                            return ( <span className="p-1" key={so.id}>{ so.id }</span>)
                                                        })
                                                       
                                                    }
                                                </td>

                                            </tr>
                                        )
                                    }) 
                                }
                                </tbody>
                            </table>
                        </div>

                    </div>
                    }
                    {
                        isEditing &&
                        <SVGProductionModule lang={lang} />
                    }
                </div>

        </div>
        )
       
    )
}